<template>
  <div>
    <h2>Intro</h2>
    <div class="manual-text-container">
      <p>The Uniform Smart city Evaluation (USE) platform has been developed by the Centre For Research and Technology Hellas (CERTH) with funding from the EU projects RESPONSE, POCITYF, IANOS, RINNO and AURORAL.
        Its purpose is to compare the results of various smart city projects by performing a threefold analysis based on the user inputs.
        The USE platform has been developed using the Python programming language for backend calculations, the Ruby on Rails software for backend and database management, the VueJs framework for frontend and the Docker app for the server development.
        In the next sections an overview of the methodology is presented as well as guidance of how to setup a project and perform each analysis.
        For more details regarding the methodology the user is referred to the publication of Kourtzanidis et al. <sup>1</sup></p>

      <div><sup>1</sup>
        <a href="https://www.mdpi.com/2071-1050/13/13/7395" target="_blank">
          Kourtzanidis et al., “Assessing Impact, Performance and Sustainability Potential of Smart City Projects: Towards a Case Agnostic Evaluation Framework.”
        </a>
      </div>
    </div>



  </div>

</template>

<script>

export default {
  name: "Intro",
}
</script>

<style scoped>

</style>